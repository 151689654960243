const jsonp = require('jsonp');

function getThumbnail(url) {
  return new Promise((resolve, reject) => {
    if (url.indexOf('wistia.com/deliveries/') > -1 && url.indexOf('.bin') > -1) {
      const thumbnail = url.replace('.bin', '.jpg?video_still_time=1&image_crop_resized=360x240');

      setTimeout(() => resolve(thumbnail), 700);//add generate thumbnail time
    }

    if (isValidYoutube(url)) {
      resolve(`https://img.youtube.com/vi/${getYoutubeId(url)}/0.jpg`);
    }

    if (isValidVimeo(url)) {

      jsonp(`http://vimeo.com/api/v2/video/${getVimeoId(url)}.json`, null, function(err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data[0].thumbnail_medium);
        }
      });
    }
  });
}

function getYoutubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return false;
  }
}

function isValidYoutube(url) {
  if (url === undefined || url === '') {
    return false;
  }

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11);
}

function isValidWistia(url) {
  return (url.indexOf('wistia.com/deliveries/') > -1 && url.indexOf('.bin') > -1);
}

function isValidVimeo(url) {
  return /^(http:\/\/|https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(url);
}

function getVimeoId(url) {
  const regExp = /^(http:\/\/|https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
  const match = url.match(regExp);

  if (match && match[4]) {
    return match[4];
  } else {
    return false;
  }
}

export {getThumbnail, getYoutubeId, isValidYoutube, isValidVimeo, getVimeoId, isValidWistia};