<template>
  <div v-if="charityInfo && (charityInfo.facebook_page_id || charityInfo.twitter)" class="sidebar-widget">

    <h3>Follow Us</h3>
    <div class="freelancer-socials margin-top-25">
      <ul>
        <li v-if="charityInfo.facebook_page_id"><a
            :href="`https://www.facebook.com/${charityInfo.facebook_page_id}`"
            target="_blank"
            title="Facebook"
            v-tippy="{ placement : 'top',  arrow: true}"
        ><font-awesome-icon :icon="['fab', 'facebook-square']"/></a></li>
        <li v-if="charityInfo.twitter"><a
            :href="`https://twitter.com/${charityInfo.twitter}`"
            target="_blank"
            title="Twitter"
            v-tippy="{ placement : 'top',  arrow: true}"
        ><font-awesome-icon :icon="['fab', 'twitter']"/></a></li>

      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['charityInfo']
  }
</script>
