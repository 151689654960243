<template>
  <div :class="{'campaigns-iframe': (version && version === 'iframe')}">
    <div class="intro-banner intro-banner-sm">
      <div class="background-image-container" v-bind:style="styleBanner"></div>
      <div class="container">

        <div class="row">
          <div class="col-md-12">
            <div class="banner-headline">
              <h4 v-if="charityInfo">
                <span>{{charityInfo.charity_name}}</span>
                <br>
                <strong>Donation Campaigns</strong>
                <div v-if="campaignsLastUpdate!==null && allCampaigns.length === 0">
                  <br>
                  <h4>There are no active campaigns at this time</h4>
                </div>
              </h4>
            </div>
          </div>
        </div>

        <!-- Search Bar -->
        <div class="row">
          <div class="col-md-10 col-xl-8">
            <div v-if="allCampaigns.length && categoriesLoaded" class="intro-banner-search-form margin-top-95">
              <!-- Search Field -->
              <div class="intro-search-field with-label">
                <label for="autocomplete-input" class="field-title ripple-effect"><font-awesome-icon
                    :icon="['far', 'search']"/> Find a Campaign </label>
                <select id="autocomplete-input"
                        v-model="selectedCampaignId"
                        v-select-picker
                        class="select-campaign default"
                        data-live-search="true"
                        title="Search by Name..."
                >
                  <optgroup v-if="categoriesLoaded && categories.length"
                            label="Categories">
                    <option v-for="(category, index) in sortedCategories"
                            :key="index"
                            :value="{type: 'category', id: category.id}"
                            :data-subtext="category.campaigns.length"
                    >{{category.name}}
                    </option>
                  </optgroup>
                  <optgroup label="Campaigns">
                    <option v-for="(campaign, index) in orderedCampaigns"
                            :key="index"
                            :value="{type: 'campaign', id: campaign.id}"
                            :data-subtext="goalPercent(campaign)"
                    >{{campaign.name}}
                    </option>
                  </optgroup>
                </select>
              </div>

              <!-- Button -->
              <div class="intro-search-button">
                <button class="button ripple-effect" v-on:click.prevent="$router.push({name: 'CampaignsGrid'})">
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Stats -->
        <div v-if="allCampaigns.length" class="row">
          <div class="col-md-12">
            <ul class="intro-stats margin-top-45 hide-under-992px">
              <li>
                <strong class="counter">{{statistic.donors}}</strong>
                <span>Donors</span>
              </li>
              <li>
                <strong class="counter">{{statistic.donations}}</strong>
                <span>Donations</span>
              </li>
              <li>
                <strong class="counter"><sup>$</sup>{{statistic.donated / 100 | formatMoney}}</strong>
                <span>Total Donations</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!(version && version === 'iframe') && sortedCategories.length>0"
         class="section margin-top-65 margin-bottom-40">
      <div class="container">
        <div class="row">

          <div class="col-xl-12">
            <!-- Section Headline -->
            <div class="section-headline margin-top-0 margin-bottom-25">
              <h3>Campaign Categories</h3>
              <router-link :to="{name: 'CampaignsCategories'}"
                           class="headline-link"
              >View All <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></router-link>
            </div>
          </div>

          <div v-for="(category, index) in sortedCategories.slice(0,8)"
               :key="index" class="col-xl-3 col-md-6">

            <router-link :to="{name: 'CampaignsGrid', query: { 'category-id': category.id }}"
                         class="photo-box small" :style="getBgStyle(category)">
              <div class="photo-box-content">
                <span>{{category.campaigns.length}}</span>
                <h3>{{category.name}}</h3>
              </div>
            </router-link>
          </div>

        </div>
      </div>
    </div>

    <div v-if="!(version && version === 'iframe')"
         class="section gray padding-top-65 padding-bottom-70 full-width-carousel-fix">
      <div class="container">
        <div class="row">

          <div class="col-xl-12">
            <!-- Section Headline -->
            <div class="section-headline margin-top-0 margin-bottom-25">
              <h3>Top Campaigns</h3>
              <router-link :to="{name: 'CampaignsGrid'}" class="headline-link"
              >View All <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></router-link>
            </div>
            <p v-if="campaignsLastUpdate!==null && !allCampaigns.length">No Campaigns Available</p>
          </div>

          <div class="col-xl-12">
            <campaigns-slider v-if="allCampaigns.length && categoriesLoaded"
                              :sortedCampaigns="allCampaigns"
                              :charityInfo="charityInfo"
            ></campaigns-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import projectMixin from '../Parts/project-mixin';
  import campaignsMixin from './campaigns-mixin';
  import CategoriesMixin from './categories-mixin-v2';
  import CampaignsSlider from './campaigns-slider.vue'

  export default {
    name: 'Campaign',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo', 'version'],
    mixins: [projectMixin, campaignsMixin, CategoriesMixin],
    components: {
      CampaignsSlider,
    },
    metaInfo() {
      return {
        title: 'Our Campaigns',
        meta: [
          {
            'property': 'og:title',
            'content': 'Our Campaigns',
          },
          {
            'property': 'og:description',
            'content': (this.charityInfo ? this.charityInfo.charity_name : ''),
          }
        ]
      }
    },
    mounted() {
      this.upCampaigns();
    },
    methods: {
      ...mapActions('campaigns', [
        'updateCampaigns',
      ]),
      upCampaigns() {
        if (this.charityInfo) {
          this.upCampaignCategories();
          this.updateCampaigns({charity_id: this.charityInfo.charity_id});
        }
      },
    },
    computed: {
      orderedCampaigns() {
        let allCampaigns = [...this.allCampaigns];

        return this.sortCampaigns(allCampaigns, 'alphabet');
      },
    },
    watch: {
      'charityInfo'() {
        this.upCampaigns();
      },
      'selectedCampaignId'() {
        if (this.selectedCampaignId.type === 'campaign' && this.selectedCampaignId.id) {
          this.$router.push({name: 'Campaign', params: {campaignId: this.selectedCampaignId.id}});
        }
        if (this.selectedCampaignId.type === 'category' && this.selectedCampaignId.id) {
          this.$router.push({name: 'CampaignsGrid', query: { 'category-id': this.selectedCampaignId.id }});
        }
      }
    },
    data() {
      return {
        selectedCampaignId: false,
      };
    },
  }
</script>
