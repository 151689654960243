<template>
  <select v-model="val" :disabled="disabled">
    <template v-for="option in options">
      <option v-if="trackBy && label"
              :value="option[trackBy]"
              :key="option[trackBy]"
              :data-subtext="subtextCallback?subtextCallback(option):''"
              :disabled="option['disabled'] || false"
              v-bind:style="{ 'white-space': selectNowrap ? 'nowrap' : 'normal' }"
      ><font-awesome-icon v-if="option['data-icon'] && option['data-icon'] === 'plus-circle'"
                          class="margin-right-8"
                          :icon="['far', 'plus-circle']"/>{{ option[label] }}
      </option>
      <option v-else :key="option">{{ option }}</option>
    </template>
  </select>
</template>


<script>
import $ from 'jquery'

export default {
  name: 'BootstrapSelect',
  props: {
    'options': Array,
    'value': [Array, String, Number],
    'trackBy': String,
    'label': String,
    'subtextCallback': null,
    'multipleOne': {
      type: Boolean,
      'default': false
    },
    'selectNowrap': {
      type: Boolean,
      'default': false
    },
    'disabled': {
      type: Boolean,
      'default': false,
    },
  },
  data() {
    return {
      val: JSON.parse(JSON.stringify(this.value)),
    }
  },
  mounted() {
    const selectPicker = $(this.$el).prop('disabled', this.disabled).selectpicker('val', this.val)

    if (this.multipleOne) {
      selectPicker.on('changed.bs.select', () => {
        selectPicker.selectpicker('toggle');
      });
    }

  },
  watch: {
    value(value) {
      this.val = JSON.parse(JSON.stringify(this.value));
      this.$nextTick(() => $(this.$el).selectpicker('val', value))
    },
    val(val, oldVal) {
      if (this.multipleOne) {
        let difference = val.filter(x => !oldVal.includes(x));

        this.$emit('input', difference)
      } else {
        this.$emit('input', val)
      }
    },
    options() {
      this.$nextTick(() => {
        $(this.$el).selectpicker('refresh')
        this.$nextTick(() => $(this.$el).selectpicker('val', this.val))
      })
    },
    disabled(value) {
      $(this.$el).prop('disabled', value).selectpicker('refresh');
    }
  }
}


</script>
