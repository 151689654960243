import {getAllCategories} from '../../utils/api'

export default {
  methods: {
    upCampaignCategories() {
      return new Promise((resolve) => {
        this.categoriesLoaded = false;
        if (this.charityInfo) {
          getAllCategories(this.charityInfo.charity_id).then(categories => {
            this.categories = categories.categories;
            this.categoriesLoaded = true;
            resolve();
          });
        }
      });
    },
    getBgStyle(category) {
      if (category.image) {
        return {'background-image': `url(${category.image})`};
      }
      return {};
    }
  },
  computed: {
    sortedCategories() {
      if (!this.categoriesLoaded) {
        return [];
      }

      let categories = this.categories.filter(category => category.campaigns && category.campaigns.length);

      return categories.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  data() {
    return {
      categories: [],
      categoriesLoaded: false
    };
  },

}