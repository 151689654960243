<template>
  <div>
    <div id="titlebar">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <h2>Campaign Categories</h2>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
              <ul>
                <li><router-link :to="{name: 'CampaignsList'}">Campaigns</router-link></li>
                <li>Categories</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- Categories -->
    <div class="section margin-top-55 margin-bottom-30">
      <div class="container">
        <div v-if="categoriesLoaded" class="row">

          <div class="col-xl-12">
            <!-- Section Headline -->
            <div class="section-headline margin-top-0 margin-bottom-25">
              <h3>{{sortedCategories.length}} Categories</h3>
              <router-link :to="{name: 'CampaignsList'}" class="headline-link"
              >View All Campaigns <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></router-link>
            </div>
          </div>

          <div v-for="(category, index) in sortedCategories"
               :key="index" class="col-xl-3 col-md-6">
            <!-- Photo Box -->
            <router-link :to="{name: 'CampaignsGrid', query: { 'category-id': category.id }}"
                         class="photo-box small" :style="getBgStyle(category)">
              <div class="photo-box-content">
                <span>{{category.campaigns.length}}</span>
                <h3>{{category.name}}</h3>
              </div>
            </router-link>
          </div>

        </div>
      </div>
    </div>
    <!-- Categories / End -->
  </div>
</template>

<script>
  import CategoriesMixin from './categories-mixin';

  export default {
    name: 'CampaignCategories',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo', 'version'],
    mixins: [CategoriesMixin],
    metaInfo() {
      return {
        title: 'Campaign Categories',
        meta: [
          {
            'property': 'og:title',
            'content': 'Campaign Categories',
          },
          {
            'property': 'og:description',
            'content': (this.charityInfo ? this.charityInfo.charity_name : ''),
          }
        ]
      }
    },
  }
</script>