<template>
  <div>
    <div class="section gray">
      <div class="container posts-list">
        <div class="row">

          <div class="col-xl-8 col-lg-8">

            <!-- Section Headline -->
            <div class="section-headline margin-top-40 margin-bottom-20">
              <h4>Recent Posts</h4>
            </div>

            <!-- Blog Post -->
            <router-link v-for="(post, index) in currentPage"
                         :key="index"
                         :to="{name: 'ImpactPost', params:{'postId':post.feedback_id}}"
                         class="blog-post">
              <!-- Blog Post Thumbnail -->
              <div class="blog-post-thumbnail">
                <div class="blog-post-thumbnail-inner">
                  <!--<span class="blog-item-tag">~1 min read</span>-->
                  <img v-if="getPostThumbnail" :src="getPostThumbnail(post)" alt="">
                </div>
              </div>
              <!-- Blog Post Content -->
              <div class="blog-post-content">
                <span class="blog-post-date primary-bg" style="color: #fff;"
                >{{post.created_at | dateFormat("MMMM D, YYYY")}}</span>
                <span class="blog-post-date"
                      style="font-style: italic; background-color: #fff;">{{post.created_at | dateFromNow}}</span>
                <h3>{{post.title}}</h3>
                <p v-html="post.body"></p>
              </div>
              <!-- Icon -->
              <div class="entry-icon"></div>
            </router-link>

            <div v-if="postsLastUpdate && allPosts.length===0" class="blog-post">
              <!-- Blog Post Thumbnail -->
              <div class="blog-post-thumbnail">
                <div class="blog-post-thumbnail-inner">
                  <span class="blog-item-tag"></span>
                  <img v-if="charityInfo && charityInfo.cover_photo"
                       :src="charityInfo.cover_photo" alt="">
                </div>
              </div>
              <!-- Blog Post Content -->
              <div class="blog-post-content">
                <span class="blog-post-date primary-bg" style="color: #fff;">COMING SOON</span>
                <span class="blog-post-date" style="font-style: italic; background-color: #fff;"></span>
                <h3>No Impact Posts Yet</h3>
                <p>Check back later to see exclusive updates on how your donations make an impact on our cause!</p>
              </div>
              <!-- Icon -->
              <div class="entry-icon"></div>
            </div>

            <div class="clearfix"></div>
            <div class="row" v-if="allPosts && allPosts.length && totalPages>1">
              <div class="col-md-12">
                <div class="pagination-container margin-top-10 margin-bottom-20">
                  <nav class="pagination">

                    <paginate
                        v-model="page"
                        :page-count="totalPages"
                        :click-handler="onPage"
                        prev-text='<i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-left">
                         <path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path>
                         </svg></i>'
                        next-text='<i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right">
                         <path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
                         </svg></i>'
                        :prev-class="'pagination-arrow'"
                        :next-class="'pagination-arrow'"
                        :page-link-class="'ripple-effect'"
                        :prev-link-class="'ripple-effect'"
                        :next-link-class="'ripple-effect'"
                    >
                    </paginate>
                  </nav>
                </div>
              </div>
            </div>
            <!-- Pagination / End -->
          </div>

          <div class="col-xl-4 col-lg-4 content-left-offset">
            <div class="sidebar-container margin-top-40">

              <!-- Search -->
              <div class="sidebar-widget margin-bottom-40">
                <h3 class="margin-bottom-25">Search Posts</h3>
                <div class="input-with-icon">
                  <input id="autocomplete-input" v-model="filter_search" type="text" placeholder="Search">
                  <i><font-awesome-icon :icon="['far', 'search']" size="xs"/></i>
                </div>
              </div>

              <!-- Widget -->
              <div class="sidebar-widget">
                <h3>Make a Donation</h3>
                <!-- Button -->
                <router-link :to="{name: 'Donate'}"
                             class="apply-now-button margin-bottom-20"
                >Donate Now <i><font-awesome-icon :icon="['far', 'heart']"/></i></router-link>
              </div>

              <sidebar-social-media :charity-info="charityInfo"></sidebar-social-media>
            </div>
          </div>

        </div>
      </div>

      <!-- Spacer -->
      <div class="padding-top-40"></div>
      <!-- Spacer -->

    </div>
  </div>

</template>

<script>
  import $ from 'jquery'
  import SidebarSocialMedia from './sidebar-social-media.vue'
  import Paginate from 'vuejs-paginate'
  import postMixin from './post-mixin';
  import {mapActions, mapState} from "vuex";

  export default {
    name: 'ImpactPosts',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    components: {
      SidebarSocialMedia,
      Paginate
    },
    mixins: [postMixin],
    mounted() {
      this.upPosts();
    },
    methods: {
      ...mapActions('posts', [
        'updatePosts',
      ]),
      upPosts() {
        if (this.charityInfo) {
          this.updatePosts({charity_id: this.charityInfo.charity_id});
        }
      },
      onPage(pageNumber) {
        this.page = pageNumber;

        $('html, body').animate({scrollTop: 0}, 300);
      },
      resetPagination() {
        this.page = 1;
      },
    },
    computed: {
      ...mapState({
        allPosts: state => state.posts.allPosts,
        postsLastUpdate: state => state.posts.lastUpdate,
      }),
      filteredPosts () {
        let allPosts = JSON.parse(JSON.stringify(this.allPosts));

        if (this.filter_search) {
          const re = new RegExp(this.filter_search, 'ig');

          allPosts = allPosts.filter(v => v.title.search(re) !== -1);
        }

        return allPosts;
      },
      currentPage () {
        const start = (this.page - 1) * this.perPage;
        return this.filteredPosts.slice(start, start + this.perPage);
      },
      totalPages () {
        return Math.ceil(this.filteredPosts.length / this.perPage);
      },
    },
    watch: {
      'charityInfo' () {
        this.upPosts();
      },
      'filter_search' () {
        this.resetPagination();
      },
    },
    data() {
      return {
        filter_search: '',
        page: 1,
        perPage: 4,
      };
    },
    metaInfo() {
      return {
        title: this.$route.meta.title,
        meta: [
          {
            'property': 'og:title',
            'content': (this.charityInfo ? this.charityInfo.charity_name : ''),
          },
          {
            'property': 'og:description',
            'content': 'See the latest updates from our organization',
          }
        ]
      }
    }
  }
</script>