<template>
  <div>
    <div class="single-page-header cmp-grid freelancer-header margin-bottom-0" v-bind:style="styleBanner">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="single-page-header-inner">
              <div class="left-side">
                <div v-if="activeCategory" class="header-details">
                  <h3>{{activeCategory.name}}</h3>
                  <ul>
                    <li>{{activeCategory.description}}</li>
                  </ul>
                </div>
                <div v-else class="header-details">
                  <h3>Our Campaigns</h3>
                  <ul>
                    <li>{{(charityInfo ? charityInfo.charity_name : '')}}</li>
                  </ul>
                </div>

              </div>
              <div class="right-side">
                <div v-if="categoriesLoaded && campaignsLastUpdate"  class="salary-box">
                  <div class="salary-type">Total Donated</div>
                  <div class="salary-amount">
                    ${{totalDonated / 100 | formatMoney}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="full-page-container">
      <div class="full-page-sidebar">
        <div class="full-page-sidebar-inner">
          <div class="sidebar-container"
               :class="{'sidebar-container-fixed': isFixedFilters}"
          >
            <!-- Search -->
            <div class="sidebar-widget">
              <div class="input-with-icon">
                <div id="autocomplete-container">
                  <input type="text"
                         v-model="searchFilter"
                         placeholder="Search...">
                </div>
                <i><font-awesome-icon :icon="['far', 'search']" size="xs"/></i>
              </div>
            </div>

            <!-- Category -->
            <div v-if="categoriesLoaded && categories.length" class="sidebar-widget margin-bottom-20">
              <h3>Categories</h3>

              <bootstrap-select v-model="selectedCategories"
                                :options="categories"
                                trackBy="id"
                                label="name"
                                class="select-categories default"
                                data-live-search="true"
                                data-selected-text-format="count"
                                title="All Categories"
                                multiple
                                :multipleOne="true"
              ></bootstrap-select>
            </div>

            <div class="clearfix"></div>

          </div>
          <!-- Sidebar Container / End -->

        </div>
      </div>
      <!-- Full Page Sidebar / End -->

      <!-- Full Page Content -->
      <div class="full-page-content-container">
        <div class="full-page-content-inner">

          <h3 v-if="campaignsLastUpdate!==null && categoriesLoaded" class="page-title">
            <a href="#">{{filteredCampaigns.length}}</a> Total Campaigns
          </h3>

          <div class="notify-box margin-top-15">
            <div class="switch-container">
              <!--campaigns "in progress" are just campaigns that have not reacched 100% of their goal yet-->
              <label class="switch">
                <input v-model="filterReached" type="checkbox">
                <span class="switch-button"></span>
                <span class="switch-text">Only show campaigns that haven't reached their goal</span>
              </label>
            </div>

            <div class="sort-by">
              <span>Sort by:</span>

              <bootstrap-select v-model="sortBy"
                                :selectNowrap="true"
                                :options="[
                                    {id: 'alphabet', name: 'Alphabetical'},
                                    {id: 'completion', name: 'Completion %'},
                                    {id: 'donations', name: 'Donations'},
                                    ]"
                                trackBy="id"
                                label="name"
                                class="select-order hide-tick"
              ></bootstrap-select>
            </div>
          </div>

          <div class="campaigns-container campaigns-grid-layout margin-top-35">
            <div v-for="(campaign, index) in currentPage"
                 :key="index"
                 class="freelancer">

              <!-- Overview -->
              <div class="freelancer-overview">
                <div class="freelancer-overview-inner">
                  <!-- Avatar -->
                  <div class="freelancer-avatar">
                    <router-link :to="{name: 'Campaign', params: {'campaignId':campaign.id}}">
                      <img :src="campaign.image || charityInfo.cover_photo" alt="">
                    </router-link>
                  </div>

                  <!-- Name -->
                  <div class="freelancer-name">
                    <h4>
                      <router-link :to="{name: 'Campaign', params: {'campaignId':campaign.id}}"
                      >{{campaign.name}}
                      </router-link>
                    </h4>
                  </div>
                </div>
              </div>

              <!-- Details -->
              <div class="freelancer-details">

                <a v-if="campaign.progress && campaign.goal"
                   href="#"
                   v-on:click.prevent=""
                   class="primary-color fw-600">{{completePercent(campaign)}}%</a>
                <span v-if="campaign.progress && campaign.goal">${{(campaign.goal / 100) | formatMoney}}</span>


                <div v-if="campaign.progress && campaign.goal" class="account-indicators">
                  <!-- Indicator -->
                  <div class="indicator">
                    <div class="indicator-bar"><span :style="{ width: completePercent(campaign)+'%'}"></span></div>
                  </div>
                </div>

                <div v-if="campaign.progress" class="freelancer-details-list">
                  <ul>
                    <li><strong>${{(campaign.progress.donated / 100) | formatMoney}}</strong>Donated</li>
                    <li v-if="campaign.goal"><strong>${{(campaign.goal / 100) | formatMoney}}</strong>Goal</li>
                    <li><strong>{{campaign.progress.donors}}</strong>Donors</li>
                  </ul>
                </div>
                <router-link :to="{name: 'Campaign', params: {'campaignId':campaign.id}}"
                             class="button button-sliding-icon"
                             v-ripple-dark
                >View Campaign <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
                </router-link>
              </div>
            </div>
          </div>

          <!-- Pagination -->
          <div class="clearfix"></div>
          <div v-if="filteredCampaigns && filteredCampaigns.length && totalPages>1"
               class="pagination-container margin-top-20 margin-bottom-20">
            <nav class="pagination">
              <paginate
                      v-model="page"
                      :page-count="totalPages"
                      :click-handler="onPage"
                      prev-text='<i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-left">
                         <path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path>
                         </svg></i>'
                      next-text='<i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right">
                         <path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
                         </svg></i>'
                      :prev-class="'pagination-arrow'"
                      :next-class="'pagination-arrow'"
                      :page-link-class="'ripple-effect'"
                      :prev-link-class="'ripple-effect'"
                      :next-link-class="'ripple-effect'"
              >
              </paginate>
            </nav>
          </div>
          <div class="clearfix"></div>
          <!-- Pagination / End -->

          <!-- Footer -->
          <div class="dashboard-footer-spacer"></div>
          <div class="small-footer margin-top-15">
            <div class="small-footer-copyrights">⚡ Powered by <strong>Harness</strong>
              | {{this.charityInfo ? this.charityInfo.charity_name : ''}}</div>
            <ul class="footer-social-links">
              <li v-if="charityInfo && charityInfo.facebook_page_id">
                <a :href="`https://www.facebook.com/${charityInfo.facebook_page_id}`"
                   target="_blank"
                   title="Facebook"
                   v-tippy="{ placement : 'bottom',  arrow: true}"
                ><font-awesome-icon :icon="['fab', 'facebook-f']"/>
                </a>
              </li>
              <li v-if="charityInfo && charityInfo.twitter">
                <a :href="`https://twitter.com/${charityInfo.twitter}`"
                   target="_blank"
                   title="Twitter"
                   v-tippy="{ placement : 'bottom',  arrow: true}"
                ><font-awesome-icon :icon="['fab', 'twitter']"/>
                </a>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
          <!-- Footer / End -->
        </div>
      </div>
      <!-- Full Page Content / End -->
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'

  import {mapActions} from 'vuex'
  import projectMixin from '../Parts/project-mixin';
  import campaignsMixin from './campaigns-mixin';
  import CategoriesMixin from './categories-mixin-v2';
  import BootstrapSelect from '../BootstrapSelect'
  import Paginate from 'vuejs-paginate'

  export default {
    name: 'CampaignsGrid',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo', 'version'],
    mixins: [projectMixin, campaignsMixin, CategoriesMixin],
    components: {
      Paginate,
      BootstrapSelect
    },
    metaInfo() {
      return {
        title: 'Our Campaigns',
        meta: [
          {
            'property': 'og:title',
            'content': 'Our Campaigns',
          },
          {
            'property': 'og:description',
            'content': (this.charityInfo ? this.charityInfo.charity_name : ''),
          }
        ]
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollFunction);
    },
    mounted() {
      window.addEventListener('scroll', this.scrollFunction);

      this.upCampaigns();

      if (!this.selectedCategory) {
        if (this.$route.query && 'category-id' in this.$route.query) {
          this.selectedCategories = [this.$route.query['category-id']];
        }
      }
    },
    methods: {
      scrollFunction() {
        this.isFixedFilters = document.querySelector('.full-page-container').getBoundingClientRect().y < 82;
      },
      ...mapActions('campaigns', [
        'updateCampaigns',
      ]),
      upCampaigns() {
        if (this.charityInfo) {
          this.upCampaignCategories();
          this.updateCampaigns({charity_id: this.charityInfo.charity_id})
        }
      },
      onPage(pageNumber) {
        this.page = pageNumber;

        $('html, body').animate({scrollTop: 0}, 300);
      },
      resetPagination() {
        this.page = 1;
      },
      filterByCategory(campaigns, categories, selectedCategory) {
        if (selectedCategory) {
          const cIds = [...categories].reduce((res, cat) => {

            if (parseInt(selectedCategory) === parseInt(cat.id)) {
              res = [...res, ...[...cat.campaigns].map(c => c.id)];
            }
            return res;
          }, []);

          campaigns = campaigns.filter(c => [...cIds].indexOf(c.id) !== -1);
        }
        return campaigns;
      }
    },
    computed: {
      selectedCategory() {
        if (this.selectedCategories.length) {
          return this.selectedCategories[0];
        }
        return false;
      },
      styleBanner() {
        let res = {};

        if (this.selectedCategory && this.activeCategory && this.activeCategory.image) {
          res['background-image'] = `url('${this.activeCategory.image}')`;
        } else if (this.charityInfo && this.charityInfo.cover_photo) {
          res['background-image'] = `url('${this.charityInfo.cover_photo}')`;
        }
        return res;
      },
      totalDonated() {
        if (this.categoriesLoaded && this.campaignsLastUpdate !== null) {
          let campaigns = this.filterByCategory([...this.allCampaigns], this.categories, this.selectedCategory);
          return campaigns.reduce((res, cmp) => {
            return cmp.progress ? (res + Number(cmp.progress.donated)) : res
          }, 0);
        }

        return 0;
      },
      filteredCampaigns() {
        let campaigns = this.sortCampaigns(this.allCampaigns, this.sortBy);

        if (this.filterReached) {
          campaigns = campaigns.filter(cmp => (!cmp.progress || !cmp.progress.complete));
        }

        if (this.searchFilter) {
          const re = new RegExp(this.searchFilter, 'ig');
          campaigns = campaigns.filter(v => v.name.search(re) !== -1);
        }

        campaigns = this.filterByCategory(campaigns, this.categories, this.selectedCategory);

        return campaigns;
      },
      currentPage() {
        const start = (this.page - 1) * this.perPage;
        return this.filteredCampaigns.slice(start, start + this.perPage);
      },
      totalPages() {
        return Math.ceil(this.filteredCampaigns.length / this.perPage);
      },
      activeCategory() {
        if (this.selectedCategory && this.categoriesLoaded) {

          return this.categories.reduce(
            (res, category) => parseInt(this.selectedCategory) === parseInt(category.id) ? category : res, null
          );
        }
        return null;
      }
    },
    watch: {
      'charityInfo'() {
        this.upCampaigns();
      },
      'selectedCategory'() {
        this.resetPagination();

        if (this.selectedCategory) {
          if (this.selectedCategory &&
            (!('category-id' in this.$route.query) || this.$route.query['category-id'] !== this.selectedCategory)
          ) {
            this.$router.replace({query: {'category-id': this.selectedCategory + ''}});
          }
        }
        else{
          this.$router.replace({query: {}});
        }
      },
      'searchFilter'() {
        this.resetPagination();
      },
      'sortBy'() {
        this.resetPagination();
      },
      'filterReached'() {
        this.resetPagination();
      }
    },
    data() {
      return {
        sortBy: 'alphabet',
        filterReached: false,
        selectedCategories: [],
        searchFilter: '',
        page: 1,
        perPage: 15,
        isFixedFilters: false
      };
    },
  }
</script>