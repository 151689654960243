import $ from 'jquery'
import {getYoutubeId, isValidYoutube} from '../../utils/video';

export default {
  mounted(){
    // Button
    function backToTop() {
      $('body').append('<div id="backtotop"><a href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-chevron-left"><path fill="currentColor" d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"></path></svg></a></div>');
    }

    backToTop();

    // Showing Button
    const pxShow = 600; // height on which the button will show
    const scrollSpeed = 500; // how slow / fast you want the button to scroll to top.

    $(window).scroll(function () {
      if ($(window).scrollTop() >= pxShow) {
        $("#backtotop").addClass('visible');
      } else {
        $("#backtotop").removeClass('visible');
      }
    });

    $('#backtotop a').on('click', function () {
      $('html, body').animate({scrollTop: 0}, scrollSpeed);
      return false;
    });
  },
  methods: {
    getPostThumbnail (post) {
      function checkImg(url) {
        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
      }

      if (checkImg(post.url)) {
        return post.url;
      }
      if (post.url.indexOf('wistia.com') > -1 && post.url.indexOf('.bin') > -1) {
        return post.url.replace('.bin', '.jpg?video_still_time=1&image_crop_resized=360x240');
      }
      if (isValidYoutube(post.url)) {
        return `https://img.youtube.com/vi/${getYoutubeId(post.url)}/0.jpg`;
      }

      return '';
    }
  }
}