<template>
  <div>

    <!-- Post Content -->
    <div class="container margin-top-20 ">

      <div class="row">

        <!-- Inner Content -->
        <div class="col-xl-8 col-lg-8">
          <!-- Blog Post -->
          <div class="blog-post single-post">

            <!-- Blog Post Thumbnail -->
            <div v-if="post" class="blog-post-thumbnail">
              <div class="blog-post-thumbnail-inner">

                <span v-if="timeToRead && post.type === 'image' && post.url" class="blog-item-tag">~{{timeToRead}} min read</span>

                <img v-if="post.type === 'image' && post.url" :src="post.url" :alt="post.title">

                <div v-if="post.type === 'video' && youtubeId" class="iframe-wrap">
                  <iframe
                      :src="`https://www.youtube.com/embed/${youtubeId}`"
                      frameborder="0"
                      allowfullscreen></iframe>
                </div>

                <video v-if="post.type === 'video' && !youtubeId" width="100%" controls>
                  <source :src="post.url" type="video/mp4">
                </video>

              </div>
            </div>

            <!-- Blog Post Content -->
            <div v-if="post" class="blog-post-content">
              <div class="blog-post-info-list margin-bottom-10">
                <a href="#" v-on:click.prevent="" class="blog-post-info primary-bg"
                   style="color: #fff;">{{post.created_at | dateFormat("MMMM D, YYYY")}}</a>
                <a href="#" v-on:click.prevent="" class="blog-post-info"
                   style="font-style: italic; background-color: rgb(255, 255, 255);">{{post.created_at | dateFromNow}}</a>
              </div>
              <h2 class="margin-bottom-15">{{post.title}}</h2>
              <p style="white-space: pre-wrap;" class="post-body" v-html="post.body"></p>

              <!-- Share Buttons -->
              <div class="share-buttons margin-top-25">
                <div class="share-buttons-trigger"><font-awesome-icon :icon="['far', 'thumbs-up']"/></div>
                <div class="share-buttons-content">
                  <span>Help by <strong>Sharing</strong></span>
                  <ul class="share-buttons-icons">
                    <li><a :href="'https://www.facebook.com/sharer/sharer.php?u='+this.pageLink"
                           style="background-color: #3b5998" title="Share on Facebook"
                           data-tippy-placement="top"
                    ><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></li>
                    <li><a
                        :href="'https://twitter.com/home?status=See how donations make a difference at ' + (charityInfo ? charityInfo.charity_name : '') + ' by checking out ' + (post ? post.title : '') + ' - ' + this.pageLink"
                        style="background-color: #1da1f2" title="Share on Twitter"
                        data-tippy-placement="top"><font-awesome-icon :icon="['fab', 'twitter']"/></a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <!-- Blog Post Content / End -->

          <!-- Blog Nav -->
          <ul id="posts-nav" class="margin-top-0 margin-bottom-40">

            <li class="next-post" v-if="postNextPrev.next">
              <router-link :to="{name: 'ImpactPost', params:{'postId':postNextPrev.next.feedback_id}}">
                <font-awesome-icon :icon="['far', 'chevron-right']" />
                <span>Next Post</span>
                <strong>{{postNextPrev.next.title}}</strong>
              </router-link>
            </li>
            <li class="prev-post" v-if="postNextPrev.prev">
              <router-link :to="{name: 'ImpactPost', params:{'postId':postNextPrev.prev.feedback_id}}">
                <font-awesome-icon :icon="['far', 'chevron-left']" />
                <span>Previous Post</span>
                <strong>{{postNextPrev.prev.title}}</strong>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Inner Content / End -->
        <div class="col-xl-4 col-lg-4 content-left-offset">
          <div class="sidebar-container">

            <!-- Button -->
            <router-link :to="{name: 'Donate', query: {'selected-method': DonationTypes.ONE_TIME}}"
                         class="apply-now-button button-sliding-icon margin-bottom-20"
            >Donate Now <i><font-awesome-icon :icon="['far', 'heart']"/></i></router-link>

            <!-- Widget -->
            <div class="sidebar-widget">

              <h3>Latest Posts</h3>
              <ul class="widget-tabs">
                <li v-for="(post, index) in allPosts.slice(0,2)"
                    :key="index">
                  <router-link :to="{name: 'ImpactPost', params:{'postId':post.feedback_id}}"
                               class="widget-content"
                               :class="{'active': index === 0}">
                    <img v-if="getPostThumbnail(post)" :src="getPostThumbnail(post)" alt="">
                    <div class="widget-text">
                      <h5>{{post.title}}</h5>
                      <span>{{post.created_at | dateFormat("MMMM D, YYYY")}}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <!-- Widget / End-->

            <sidebar-social-media :charity-info="charityInfo"></sidebar-social-media>

          </div>
        </div>
      </div>
    </div>

    <!-- Spacer -->
    <div class="padding-top-40"></div>
    <!-- Spacer -->

  </div>

</template>

<script>
  import $ from 'jquery'
  import {getImpactPost} from '../../utils/api'
  import {getYoutubeId} from '../../utils/video'
  import SidebarSocialMedia from './sidebar-social-media.vue'
  import postMixin from './post-mixin';
  import {mapActions, mapState} from "vuex";
  import {shorten, stripHtml} from "@/utils/utils";


  /**
   <title>Page Title. Maximum length 60-70 characters</title>
   <meta name="description" content="Page description. No longer than 155 characters." />

   <!-- Open Graph data -->
   <meta property="og:title" content="Title Here" />
   <meta property="og:url" content="http://www.example.com/" />
   <meta property="og:image" content="http://example.com/image.jpg" />
   <meta property="og:description" content="Description Here" />
   */

  export default {
    name: 'ImpactPost',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    mixins: [postMixin],
    metaInfo() {
      return {
        title: this.post && this.post.title || '',
        meta: [
          {
            'property': 'description',
            'content': shorten(this.post && stripHtml(this.post.body) || '', 155),
          },
          {
            'property': 'og:title',
            'content': this.post && this.post.title || '',
          },
          {
            'property': 'og:description',
            'content': shorten(this.post && stripHtml(this.post.body) || '', 200),
          },
          {
            'property': 'og:url',
            'content': this.pageLink,
          },
          {
            'property': 'og:image',
            'content': this.post && this.getPostThumbnail(this.post) || '',
          }
        ]
      }
    },
    mounted() {
      this.upPosts();
      getImpactPost(this.$route.params.postId).then(post => this.post = post);
    },
    components: {
      SidebarSocialMedia,
    },
    methods: {
      ...mapActions('posts', [
        'updatePosts',
      ]),
      upPosts() {
        if (this.charityInfo) {
          this.updatePosts({charity_id: this.charityInfo.charity_id});
        }
      },
    },
    watch: {
      '$route'() {
        this.post = null;
        getImpactPost(this.$route.params.postId).then(post => this.post = post);
        $('html, body').animate({scrollTop: 0}, 500);
      },
      'charityInfo'() {
        this.upPosts();
      },
    },
    computed: {
      ...mapState({
        allPosts: state => state.posts.allPosts,
        postsLastUpdate: state => state.posts.lastUpdate,
      }),
      youtubeId() {
        if (this.post) {
          return getYoutubeId(this.post.url);
        }
        return '';
      },
      postNextPrev() {
        let res = {
          next: false,
          prev: false,
        };
        if (this.allPosts.length > 0 && this.post) {

          for (let i = 0; i < this.allPosts.length; i++) {
            if (this.allPosts[i].feedback_id === this.post.feedback_id) {
              if ((i - 1) in this.allPosts) {
                res['prev'] = this.allPosts[i - 1];
              }
              if ((i + 1) in this.allPosts) {
                res['next'] = this.allPosts[i + 1];
              }
            }
          }

        }
        return res;
      },
      timeToRead() {
        if (this.post) {
          return Math.ceil(this.post.body.split(" ").length / 250);
        }

        return false;
      }
    },
    data() {
      return {
        post: null,
        pageLink: encodeURIComponent(window.location.href)
      };
    },
  }
</script>
