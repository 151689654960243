<template>
  <div>
    <!-- Post Content -->
    <div class="background-tile">
    </div>
    <div class="container container-cpm margin-top-30">
      <div class="row">
        <!-- Inner Content -->
        <div class="col-xl-7 col-lg-7">
          <!-- Blog Post -->
          <div v-if="campaign" class="blog-post single-post">

            <!-- Blog Post Thumbnail -->
            <div class="blog-post-thumbnail">
              <div class="blog-post-thumbnail-inner">
                <span v-if="daysLeft" class="blog-item-tag">{{daysLeft}}</span>
                <img :src="campaign.image?campaign.image:charityInfo.cover_photo" :alt="campaign.name">

              </div>
            </div>

            <div class="blog-post-content">
              <h5 v-if="campaign.categories && campaign.categories.length"
                  style="margin-top: -15px">
                <router-link :to="{name: 'CampaignsGrid'}">Campaigns</router-link>
                |
                <router-link :to="{name: 'CampaignsGrid', query: { 'category-id': campaign.categories[0].id }}"
                >{{campaign.categories[0].name}}
                </router-link>
              </h5>

              <h2>{{campaign.name}}</h2>

              <a v-if="campaign.progress && campaign.goal && campaign.showGoal"
                 v-on:click.prevent=""
                 href="#"
                 class="primary-color fw-600"
              >{{completePercent(campaign)}} complete</a>
              <span v-if="campaign.progress && campaign.goal && campaign.showGoal"
                    style="float: right;"
              >${{(campaign.goal / 100) | formatMoney}}</span>

              <!-- Indicator -->
              <div v-if="campaign.progress && campaign.goal && campaign.showGoal" class="roundup-indicators">
                <div class="indicator">
                  <div class="indicator-bar"><span :style="{ width: completePercent(campaign, 1)}"></span></div>
                </div>
              </div>

              <!-- Profile Overview -->
              <div v-if="campaign.progress && campaign.showGoal" class="profile-overview margin-top-15">
                <div class="overview-item"><strong>${{(campaign.progress.donated / 100) | formatMoney}}</strong><span>Donated</span>
                </div>
                <div v-if="campaign.goal" class="overview-item">
                  <strong>${{(campaign.goal / 100) | formatMoney}}</strong><span>Our Goal</span></div>
                <div v-if="campaign.showTotalDonors" class="overview-item"><strong>{{campaign.progress.donors}}</strong><span>Donors</span></div>
              </div>

              <p style="white-space: pre-wrap;" v-html="campaign.description"></p>

              <!-- Button -->
              <router-link v-if="campaign.defaultFlowId"
                           :to="{name: 'Donate', query: { checkout: campaign.defaultFlowId, campaign_id: campaign.id }}"
                           v-on:click.native="clickDonate"
                           class="apply-now-button button-sliding-icon margin-top-20 margin-bottom-0">
                Donate Now <i><font-awesome-icon :icon="['far', 'heart']" /></i>
              </router-link>
              <router-link v-else
                           :to="{name: 'Donate', query: { campaign_id: campaign.id }}"
                           v-on:click.native="clickDonate"
                           class="apply-now-button button-sliding-icon margin-top-20 margin-bottom-0">
                Donate Now <i><font-awesome-icon :icon="['far', 'heart']" /></i>
              </router-link>

            </div>
          </div>
          <div v-else-if="campaignsLastUpdate!==null" class="blog-post single-post">

            <!-- Blog Post Thumbnail -->
            <div class="blog-post-thumbnail">
              <div class="blog-post-thumbnail-inner">
                <img :src="charityInfo.cover_photo">
              </div>
            </div>

            <div class="blog-post-content">

              <h2>This campaign has been archived</h2>

              <p style="white-space: pre-wrap;">Thank you to everyone who helped support this campaign!</p>

              <!-- Button -->
              <router-link :to="{name: 'CampaignsList'}"
                           class="apply-now-button button-sliding-icon margin-top-20 margin-bottom-0">
                Campaigns List
                <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
              </router-link>

            </div>
          </div>
          <!-- Blog Post Content / End -->

          <!-- Blog Nav -->
          <ul v-if="campaignNextPrev.next || campaignNextPrev.prev"
              id="posts-nav"
              class="margin-top-0 margin-bottom-40">
            <li class="next-post">
              <router-link v-if="campaignNextPrev.next"
                           :to="{name: 'Campaign', params:{'campaignId':campaignNextPrev.next.id}}">
                <font-awesome-icon :icon="['far', 'chevron-right']" />
                <span>Next Campaign</span>
                <strong>{{campaignNextPrev.next.name}}</strong>
              </router-link>
            </li>
            <li class="prev-post">
              <router-link v-if="campaignNextPrev.prev"
                           :to="{name: 'Campaign', params:{'campaignId':campaignNextPrev.prev.id}}">
                <font-awesome-icon :icon="['far', 'chevron-left']" />
                <span>Previous Campaign</span>
                <strong>{{campaignNextPrev.prev.name}}</strong>
              </router-link>
            </li>
          </ul>

          <!-- Related Posts -->
          <div v-if="campaign && campaign.showUpdates && allPosts.length" class="row">

            <!-- Headline -->
            <div class="col-xl-12">
              <h3 class="margin-top-20 margin-bottom-35">Updates & Posts</h3>
            </div>

            <div class="col-md-6" v-for="(post, index) in allPosts.slice(0,2)"
                 :key="index">
              <router-link :to="{name: 'ImpactPost', params:{'postId':post.feedback_id}}"
                           class="blog-compact-item-container">
                <div class="blog-compact-item">
                  <img v-if="getPostThumbnail" :src="getPostThumbnail(post)" alt="">
                  <span class="blog-item-tag">{{post.created_at | dateFromNow}}</span>
                  <div class="blog-compact-item-content">
                    <ul class="blog-post-tags">
                      <li>{{post.created_at | dateFormat("MMMM D, YYYY")}}</li>
                    </ul>
                    <h3>{{post.title}}</h3>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
          <!-- Related Posts / End -->

          <!-- Comments -->
          <div class="row" v-if="campaign && campaign.showNotes && commentsWithNote.length">
            <div class="col-xl-12">
              <section class="comments margin-bottom-45">
                <h3 class="margin-top-15 margin-bottom-0">Notes & Comments
                  <span class="comments-amount">({{commentsWithNote.length}})</span>
                </h3>

                <transition-group name="list-complete" tag="ul">

                  <li v-for="(comment) in commentsShow"
                      :key="comment.created_at"
                      class="list-complete-item"
                  >
                    <div class="avatar"><img
                        :src="comment.profile_photo?comment.profile_photo:require('../Parts/user-avatar-placeholder.png')"
                        alt=""></div>
                    <div class="comment-content">
                      <div class="arrow-comment"></div>
                      <div class="comment-by">{{comment.fname}} {{comment.lname}}.
                        <span class="date">{{comment.created_at | dateFormat("MMMM D, YYYY")}}</span>
                      </div>
                      <p>{{comment.note}}</p>
                    </div>
                  </li>
                </transition-group>

                <!-- Button -->
                <a href="#"
                   v-if="commentsLimit<commentsWithNote.length"
                   v-on:click.prevent="commentsLimit+=3"
                   class="apply-now-button button-sliding-icon margin-bottom-0"
                >View More <i class="margin-left-7"><font-awesome-icon :icon="['far', 'arrow-down']"/></i></a>

              </section>

            </div>
          </div>
          <!-- Comments / End -->
        </div>

        <div class="col-xl-5 col-lg-5 content-left-offset">
          <div class="sidebar-container">
            <section v-if="campaign && campaign.showRecentDonations && commentsLoaded && comments.length" class="comments">
              <h3 class="margin-top-10 margin-bottom-0">Recent Donations <span class="comments-amount">
                <a href="#"
                   v-if="donationsLimit<comments.length"
                   v-on:click.prevent="donationsLimit+=4"
                >View More</a></span>
              </h3>

              <ul>
                <transition-group name="list-complete" tag="p">
                  <li v-for="(comment) in donationsShow"
                      :key="comment.created_at"
                      class="list-complete-item">
                    <div class="avatar"><img
                        :src="comment.profile_photo?comment.profile_photo:require('../Parts/user-avatar-placeholder.png')"
                        alt=""></div>
                    <div class="comment-content">
                      <div class="arrow-comment"></div>
                      <div class="comment-by">{{comment.fname}} {{comment.lname}}.<span
                          class="date">{{comment.created_at | dateFromNow}}</span>
                        <a href="#" v-on:click.prevent="" class="reply"><i class="fa fa-reply"></i>
                          ${{comment.amount / 100 | formatMoney}}</a>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </ul>
              <!-- Button -->
              <a href="#"
                 v-if="donationsLimit<comments.length"
                 v-on:click.prevent="donationsLimit+=4"
                 class="apply-now-button button-sliding-icon margin-bottom-0"
              >View More <i class="margin-left-7"><font-awesome-icon :icon="['far', 'arrow-down']"/></i></a>

            </section>

            <div v-else class="margin-top-20">&nbsp;</div>



            <!-- Share Buttons -->
            <div class="share-buttons margin-top-30">
              <div class="share-buttons-trigger"><font-awesome-icon :icon="['far', 'thumbs-up']"/></div>
              <div class="share-buttons-content">
                <span>Help by <strong>Sharing</strong></span>
                <ul class="share-buttons-icons">
                  <li><a
                      :href="'https://www.facebook.com/sharer/sharer.php?u=' + pageLink"
                      style="background-color: #3b5998"
                      title="Share on Facebook"
                      target="_blank"
                      v-tippy="{ placement : 'top',  arrow: true}"
                  ><font-awesome-icon :icon="['fab', 'facebook-f']"/>
                  </a>
                  </li>
                  <li><a
                      :href="'https://twitter.com/home?status=Join me in supporting '+ (charityInfo ? charityInfo.charity_name : '') +' by donating to '+ (campaign ? campaign.name : '') +'! Use this link to give: ' + pageLink"
                      style="background-color: #1da1f2"
                      target="_blank"
                      title="Share on Twitter"
                      v-tippy="{ placement : 'top',  arrow: true}"
                  ><font-awesome-icon :icon="['fab', 'twitter']"/></a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="padding-top-40"></div>
  </div>

</template>

<script>
  import $ from 'jquery'
  import {getAllPublicDonations} from '../../utils/api'
  import postMixin from '../Posts/post-mixin';
  import {mapActions, mapState} from 'vuex'
  import {shorten, stripHtml} from "@/utils/utils";

  const moment = require('moment');

  export default {
    name: 'Campaign',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    mixins: [postMixin],
    metaInfo() {
      return {
        title: (this.campaign ? this.campaign.name : (this.campaignsLastUpdate !== null ? 'This campaign has been archived' : '')),
        meta: [
          {
            'property': 'description',
            'content': shorten(this.campaign && stripHtml(this.campaign.description) || '', 155),
          },
          {
            'property': 'og:title',
            'content': this.campaign && this.campaign.name || '',
          },
          {
            'property': 'og:description',
            'content': shorten(this.campaign && stripHtml(this.campaign.description) || '', 200),
          },
          {
            'property': 'og:url',
            'content': this.pageLink,
          },
          {
            'property': 'og:image',
            'content': this.campaign && this.campaign.image && this.campaign.image ||
                (this.charityInfo && this.charityInfo.cover_photo || ''),
          }
        ]
      }
    },
    mounted() {
      $('html, body').animate({scrollTop: 0}, 100);
      setInterval(() => this.timeNow = moment(), 60 * 1000);

      this.upCampaigns();
      this.upPosts();
    },
    methods: {
      clickDonate() {
        this.$tracking.track(this.$tracking.EVENTS.LEAD, {
          'content_category': 'campaign_donate',
          'content_name': this.campaign && this.campaign.name || ''
        });
      },
      ...mapActions('campaigns', [
        'updateCampaigns',
      ]),
      ...mapActions('posts', [
        'updatePosts',
      ]),
      completePercent (campaign, decimal = 0) {
        let percent = campaign.progress.donated / campaign.goal * 100;

        if(decimal > 0) {
            percent = Math.floor(percent * Math.pow(10, decimal)) / Math.pow(10, decimal);
        }
        else {
            percent = Math.floor(percent);
        }

        return percent + '%';
      },
      upPosts() {
        if (this.charityInfo) {
          this.updatePosts({charity_id: this.charityInfo.charity_id});
        }
      },
      upCampaigns() {
        if (this.charityInfo) {
          this.updateCampaigns({charity_id: this.charityInfo.charity_id}).then(() => {
            this.updateCampaign();
          });
        }
      },
      updateCampaign() {
        if (this.allCampaigns.length) {
          this.campaign = this.allCampaigns.reduce((res, cmp) => cmp.id == this.$route.params.campaignId ? cmp : res, null);
        } else {
          this.campaign = null;
        }

        this.comments = [];
        this.commentsLoaded = false;
        if (this.campaign) {
          this.commentsLimit = 3;
          getAllPublicDonations(this.charityInfo.charity_id, this.campaign.id)
            .then(comments => {
              this.comments = comments.donations;
              this.commentsLoaded = true;
            });
        }
      }
    },
    watch: {
      '$route'() {
        this.campaign = null;
        this.updateCampaign();
        $('html, body').animate({scrollTop: 0}, 500);
      },
      'charityInfo'() {
        this.upCampaigns();
        this.upPosts();
      },
    },
    computed: {
      ...mapState({
        allPosts: state => state.posts.allPosts,
        campaignsLastUpdate: state => state.campaigns.lastUpdate,
      }),
      allCampaigns() {
        return this.$store.getters['campaigns/allCampaignsWithDefault'](this.charityInfo);
      },
      commentsWithNote() {
        return this.comments.filter(c => c.note);
      },
      commentsShow() {
        return this.commentsWithNote.slice(0, this.commentsLimit);
      },
      donationsShow() {
        return this.comments.slice(0, this.donationsLimit);
      },
      daysLeft() {
        if (this.campaign && this.campaign.endsAt) {
          const endsAt = moment(this.campaign.endsAt);
          if (endsAt > this.timeNow) {
            return endsAt.fromNow(true) + ' left';
          }
        }
        return false;
      },
      campaignNextPrev() {
        let res = {
          next: false,
          prev: false,
        };
        if (this.allCampaigns.length > 0 && this.campaign) {

          for (let i = 0; i < this.allCampaigns.length; i++) {
            if (this.allCampaigns[i].id === this.campaign.id) {
              if ((i - 1) in this.allCampaigns) {
                res['prev'] = this.allCampaigns[i - 1];
              }
              if ((i + 1) in this.allCampaigns) {
                res['next'] = this.allCampaigns[i + 1];
              }
            }
          }
        }
        return res;
      },
    },
    data() {
      return {
        timeNow: moment(),
        // allPosts: [],
        campaign: null,
        comments: [],
        commentsLoaded: false,
        commentsLimit: 3,
        donationsLimit: 4,
        pageLink: encodeURIComponent(window.location.href)
      };
    },
  }
</script>